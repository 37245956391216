/**
 * NOTE: It's very important to import getMatchMediaString not from the root of the `@haaretz/l-theme-shared` package,
 * because we don't want to include all the package code into the client bundle.
 */
import { getMatchMediaString } from '@haaretz/l-theme-shared/lib/getMqString';
import usePlatform from '@haaretz/s-atoms/platform';
import * as React from 'react';

export type Breakpoint = 'default' | 's' | 'm' | 'l' | 'xl' | 'xxl';
type MediaMatchers = Record<Breakpoint, MediaQueryList>;
type MQ = Readonly<[keyof MediaMatchers, ReturnType<typeof getMatchMediaString>]>;

const mediaQueries: Readonly<MQ[]> = [
  ['default', getMatchMediaString({ until: 's' })],
  ['s', getMatchMediaString({ from: 's', until: 'm' })],
  ['m', getMatchMediaString({ from: 'm', until: 'l' })],
  ['l', getMatchMediaString({ from: 'l', until: 'xl' })],
  ['xl', getMatchMediaString({ from: 'xl', until: 'xxl' })],
  ['xxl', getMatchMediaString({ from: 'xxl' })],
] as const;

// An array used for bookkeeping purposes so that we can
// know when it is safe to remove event listeners
const listenerSubscriptions: Array<[string, boolean]> = [];

/**
 * Indicates what is the currently active breakpoint.
 * On the server, we will do our best to determine the
 * active breakpoint based on `platform`, but we can only
 * do this for mobile (`mobile` and `app` platform),
 * since `platform` doesn't have that kind of granularity
 * for desktop. A return value of `undefined` means we are
 * on the server and couldn't determine the exact active
 * breakpoint, but it's one of the non-mobile ones
 * ('s' | 'm' | 'l' | 'xl' | 'xxl').
 *
 * **Note:** We return `default` for both the `app` and `mobile`
 * values of `platform`. If you need to know the difference between
 * them, rely on `platform` instead.
 */
export default function useBreakpoint() {
  const platform = usePlatform();

  const defaultBp = ['app', 'mobile'].includes(platform) ? 'default' : undefined;
  const [breakpoint, setBreakpoint] = React.useState<Breakpoint | undefined>(defaultBp);
  const subscriptionIdentifier = React.useId();

  React.useEffect(() => {
    // `App` is immutable so we don't need to check for changes
    if (platform === 'app')
      return () => {
        return undefined;
      };
    const mediaMatchers = mediaQueries.map(([bpName, mq]) => {
      const matcher: [Breakpoint, MediaQueryList] = [
        bpName,
        typeof window !== 'undefined'
          ? window.matchMedia(mq)
          : ({ matches: false } as MediaQueryList),
      ];
      return matcher;
    });

    const listeners = {} as Record<keyof MediaMatchers, (evt: MediaQueryListEvent) => void>;

    for (const [bp, matcher] of mediaMatchers) {
      // If event listeners aren't already registered,
      // create and register them for each breakpoint
      if (!listeners[bp]) {
        listeners[bp] = (evt: MediaQueryListEvent) => {
          if (evt.matches) setBreakpoint(bp);
        };
        matcher.addEventListener('change', listeners[bp]);
      }
    }

    // Check if element hook invocation had already registered itself
    // as subscribed to listeners for bookkeeping purposes
    // --------------------------------------------------------------
    let activeSubscription = listenerSubscriptions.find(([id]) => id === subscriptionIdentifier);
    // 1. Never registered, need to create a new entry
    if (!activeSubscription) {
      activeSubscription = [subscriptionIdentifier, true];
      listenerSubscriptions.push(activeSubscription);
    }
    // 2. Was registered and unregistered, need to re-register as subscribed
    else if (!activeSubscription[1]) activeSubscription[1] = true;

    // The current active breakpoint
    const currentMatcher = mediaMatchers.find(([, matcher]) => matcher.matches);

    if (currentMatcher) {
      const bp = currentMatcher[0];
      setBreakpoint(bp);
      // // Update `platform` based on current breakpoint
      // setPlatform(bp === 'default' ? 'mobile' : 'desktop');
    }

    return () => {
      // Cleaning up:
      // --------------------
      // 1. Indicate that current subscription is no longer registered
      if (activeSubscription) activeSubscription[1] = false;

      // 2. Check if there are any active subscriptions left
      const hasActiveSubscriptions = listenerSubscriptions.some(([, isActive]) => isActive);

      // 3. If there are no active subscriptions left, remove all event listeners
      if (!hasActiveSubscriptions) {
        for (const [bp, matcher] of mediaMatchers) {
          if (listeners[bp]) {
            matcher.removeEventListener('change', listeners[bp]);
            delete listeners[bp];
          }
        }
      }
    };
  }, [breakpoint, platform, subscriptionIdentifier]);

  return breakpoint;
}
