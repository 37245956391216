'use server';
import throwError from '@haaretz/s-common-utils/throwError';
import { fetchClusterServerQuery } from '@haaretz/s-server-queries/Cluster.server';

import clusterSlotComponentResolver from '../clusterSlotComponentResolver';

import type { BaseServerActionParams } from '@haaretz/s-types';

const defaultResponse: JSX.Element | null = null;

export default async function loadClusterItems({
  items,
  mainContentId,
}: {
  items: string;
  mainContentId: string;
} & BaseServerActionParams) {
  if (!items) {
    throwError('Cluster items string is required');

    return defaultResponse;
  }

  try {
    const result = await fetchClusterServerQuery({
      items,
      mainContentId,
    })();

    const clusterItems = result.Cluster?.items || [];

    if (!Array.isArray(clusterItems) || clusterItems.length === 0) return defaultResponse;

    return clusterItems.map(clusterSlotComponentResolver);
  } catch (error) {
    console.error(`Cluster items action failed: ${(error as Error).message}`);

    return defaultResponse;
  }
}
