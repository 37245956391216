'use client';

import color from '@haaretz/l-color.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
import Icon from '@haaretz/s-icon';
import useBreakpoint from '@haaretz/s-use-breakpoint';
import React from 'react';
import s9 from 'style9';

const blockPadding = space(4);
const inlinePadding = space(4);
const blurWidth = space(14);

// `c` is short for `classNames`
const c = s9.create({
  base: {
    ...mq({
      until: 'xl',
      value: {
        display: 'none',
      },
    }),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: blockPadding,
    left: inlinePadding,
    bottom: blockPadding,
    zIndex: zIndex('above'),
    transform: 'rotate(180deg)',
    height: 'max-content',
    marginTop: 'auto',
    marginBottom: 'auto',
    backgroundColor: 'transparent',

    ':after': {
      content: '""',
      position: 'absolute',

      top: blockPadding,
      left: inlinePadding,
      bottom: blockPadding,
      backgroundImage: `linear-gradient(to left, transparent, ${color('cardOutbrain')} 50%)`,
      width: blurWidth,
    },
  },
  right: {
    left: 'initial',
    right: inlinePadding,
    transform: 'initial',
  },
  blur: {
    position: 'absolute',
    top: blockPadding,
    bottom: blockPadding,
    width: blurWidth,
  },
  blurEnd: {
    backgroundImage: `linear-gradient(to left, transparent, ${color('cardOutbrain')} 50%)`,
    left: `calc(${inlinePadding} - ${space(1)})`,
  },
  blurStart: {
    right: `calc(${inlinePadding} - ${space(1)})`,
    backgroundImage: `linear-gradient(to right, transparent, ${color('cardOutbrain')} 50%)`,
  },
  wideWrapper: {
    scrollBehavior: 'smooth',

    ...mq({
      from: 'xl',
      value: {
        display: 'flex',
        alignItems: 'center',
        columnGap: space(3),
        overflowX: 'hidden',
        scrollPaddingInlineEnd: blurWidth,
      },
    }),
  },
});

type ArrowDirection = 'right' | 'left';

type ScrollWrapperProps = {
  /** The Children to be rendered inside `<ScrollWrapper>` */
  children?: React.ReactNode;
};

export default function ScrollWrapper({ children }: ScrollWrapperProps) {
  const breakpoint = useBreakpoint() || 'default';
  const [arrowDirection, setArrowDirection] = React.useState<ArrowDirection | null>('left');
  const validBreakpoints = ['xl', 'xxl'];
  const ref = React.useRef<HTMLDivElement>(null);

  if (!validBreakpoints.includes(breakpoint)) return <>{children}</>;

  const onClick = () => {
    if (ref.current) {
      setArrowDirection(prev => (prev === 'left' ? 'right' : 'left'));
      ref.current.scrollTo({
        behavior: 'smooth',
        left: arrowDirection === 'left' ? -ref.current.scrollWidth : 0,
      });
    }
  };

  return (
    <div className={s9(c.wideWrapper)} ref={ref} id="wide-wrapper">
      <div className={s9(c.blur, arrowDirection === 'right' ? c.blurStart : c.blurEnd)} />
      <button className={s9(c.base, arrowDirection === 'right' && c.right)} onClick={onClick}>
        <Icon icon="chevron" />
      </button>
      {children}
    </div>
  );
}
