export default function throwError(error: string | Error) {
  if (process.env.NODE_ENV === 'development') {
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error(error);
    }
  } else {
    console.error(error);
  }
}
