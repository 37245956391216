'use client';

import React from 'react';
import s9 from 'style9';

import type { StaticElementProps } from './StaticElement.types';

const c = s9.create({
  defaultPlacement: {
    gridColumnStart: 'gutter',
    gridColumnEnd: 'gutter',
  },
});

const interactiveScriptCounter: Record<string, number> = {};

export default function StaticElement({ styleExtend = [], rawHtml }: StaticElementProps) {
  const elRef = React.useRef<HTMLElement>(null);

  React.useLayoutEffect(() => {
    const el = elRef.current;

    if (!el || !rawHtml) return;

    const range = document.createRange();
    range.selectNode(el);

    const htmlString = rawHtml.replaceAll(/module-(.+)\.js/g, (match, scriptName) => {
      interactiveScriptCounter[scriptName] = interactiveScriptCounter[scriptName] || 0;
      interactiveScriptCounter[scriptName] += 1;

      const counter = interactiveScriptCounter[scriptName] - 1;

      if (!counter) {
        return match;
      }

      return `${match}?${counter}`;
    });

    const documentFragment = range.createContextualFragment(htmlString);

    el.innerHTML = '';
    el.append(documentFragment);
  }, [rawHtml]);

  if (!rawHtml) return null;

  return (
    <figure
      className={s9(c.defaultPlacement, ...styleExtend)}
      data-test="articleHtmlElement"
      ref={elRef}
    />
  );
}
